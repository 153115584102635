module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Skyelar Carroll | Software Developer","short_name":"Skyelar Carroll","start_url":"/","background_color":"#112a3a","theme_color":"#112a3a","display":"minimal-ui","icon":"src/assets/images/milky-way-emoji.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"40ae2da356b03f18783425c9658770fd"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
